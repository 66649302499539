@font-face {
    font-family: "MyFont";
    src: url(./components/assets/Bossa-ExpandedBold.woff) format("woff");
    font-weight: normal;
    font-style: normal;
  }

  /*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * mr-eaves-sans:
 *   - http://typekit.com/eulas/00000000000000007735b344
 *
 * © 2009-2024 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2024-02-21 08:31:18 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=byr2kdo&ht=tk&f=28415.31139&a=10432062&app=typekit&e=css");

@font-face {
font-family:"mr-eaves-sans";
src:url("https://use.typekit.net/af/ea6c31/00000000000000007735b344/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff2"),url("https://use.typekit.net/af/ea6c31/00000000000000007735b344/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff"),url("https://use.typekit.net/af/ea6c31/00000000000000007735b344/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:200;font-stretch:normal;
}

@font-face {
font-family:"interstate";
src:url("https://use.typekit.net/af/7aa905/00000000000000003b9ae7f2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/7aa905/00000000000000003b9ae7f2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/7aa905/00000000000000003b9ae7f2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}